import { KeyOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PasswordChangeModal from "../components/PasswordChangeModal/PasswordChangeModal";
import UserService from "../services/user.service";
import serverErrorHandler from "../utils/serverValidationHandler";

function UserProfile() {
  const { userid } = useParams();
  const [userForm] = Form.useForm();
  const [oldValues, setOldValues] = useState(null);
  const [changes, setChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async (id) => {
      const userData = await UserService.getOne(id);
      const { lastname, firstname, middlename } = userData.data;
      userForm.setFieldsValue({ lastname, firstname, middlename });
      setOldValues({ lastname, firstname, middlename });
    };

    setIsLoading(true);
    try {
      fetchUser(userid);
    } catch (e) {
      message.error(e.response?.data);
    } finally {
      setIsLoading(false);
    }
  }, [userid, userForm]);

  const onFinish = async () => {
    try {
      const formData = userForm.getFieldsValue();
      await UserService.update(
        userid,
        formData.lastname,
        formData.firstname,
        formData.middlename
      );
      setOldValues({ ...userForm.getFieldsValue() });
      setChanges(false);
      message.success("Изменения сохранены", 3);
    } catch (e) {
      userForm.setFields(serverErrorHandler(e.response?.data));
    }
  };

  const onReset = () => {
    userForm.setFieldsValue(oldValues);
    setChanges(false);
  };

  const onChange = () => {
    setChanges(true);
  };

  const onSuccses = (value) => {
    setModalOpen(false);
    message.success(value);
  };

  return (
    <Skeleton active={true} loading={isLoading}>
      <Form
        name="edit-profile"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFieldsChange={onChange}
        form={userForm}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item
          name="lastname"
          label="Фамилия"
          rules={[
            {
              required: true,
              message: "Пожалуйста введите фамилию!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="firstname"
          label="Имя"
          rules={[
            {
              required: true,
              message: "Пожалуйста введите имя!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="middlename" label="Отчество">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "8px" }}
            disabled={!changes}
          >
            Сохранить
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            disabled={!changes}
            style={{ marginRight: "8px" }}
          >
            Сбросить
          </Button>

          <Button
            htmlType="button"
            onClick={() => setModalOpen(true)}
            style={{ float: "right" }}
            icon={<KeyOutlined />}
          >
            Сменить пароль
          </Button>
        </Form.Item>
      </Form>

      <PasswordChangeModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onSuccses={onSuccses}
      />
    </Skeleton>
  );
}

export default UserProfile;
