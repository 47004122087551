import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index";

function MenuLoggedIn() {
  const { authStore } = useContext(Context);
  const router = useNavigate();

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectable={false}
      className="headder-menu"
    >
      <Menu.SubMenu
        title={authStore.user.email}
        icon={<UserOutlined />}
        key="user"
      >
        <Menu.Item
          key="profile"
          icon={<LogoutOutlined />}
          onClick={() => router("user/" + authStore.user.id)}
        >
          Профиль
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => authStore.logout()}
        >
          Выйти
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

export default observer(MenuLoggedIn);
