import { LoginOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";

function MenuLoggedOut() {
  const router = useNavigate();

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectable={false}
      className="headder-menu"
    >
      <Menu.Item
        key="login"
        icon={<LoginOutlined />}
        onClick={() => router("/login")}
      >
        Войти
      </Menu.Item>
      <Menu.Item
        key="register"
        icon={<PlusCircleOutlined />}
        onClick={() => router("/register")}
      >
        Регистрация
      </Menu.Item>
    </Menu>
  );
}

export default observer(MenuLoggedOut);
