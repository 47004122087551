import { observer } from "mobx-react-lite";
import "moment/locale/ru";
import React, { useContext, useEffect } from "react";
import AppRouter from "./components/AppRouter/AppRouter";
import { Context } from "./index";
import "./styles/app.css";

import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";

function App() {
  const { authStore } = useContext(Context);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      authStore.checkAuth();
    } else {
      authStore.setAuth(false);
    }
  }, [authStore]);

  return (
    <ConfigProvider locale={ruRU}>
      <AppRouter />
    </ConfigProvider>
  );
}

export default observer(App);
