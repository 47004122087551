import axios from "axios";
import api from "../utils/api.js";

class AuthService {
  static async login(email, password) {
    return api.post("/user/login", { email, password });
  }

  static async register(
    email,
    password,
    lastname,
    firstname,
    middlename = null
  ) {
    return api.post("/user/register", {
      email,
      password,
      lastname,
      firstname,
      middlename,
    });
  }

  static async logout() {
    return api.post("/user/logout");
  }

  static async refresh() {
    return axios.get(`${process.env.REACT_APP_API_URL}/user/refresh`, {
      withCredentials: true,
    });
  }
}

export default AuthService;
