import { DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
import { useContext } from "react";
import { Context } from "../../index";
import CompService from "../../services/comp.service";
import serverErrorHandler from "../../utils/serverValidationHandler";

function NewCompModal({ open, onSuccses, onCancel }) {
  const [newCompForm] = Form.useForm();
  const { authStore } = useContext(Context);

  const onOk = async () => {
    // Надо прописать серверную валидацию!
    await newCompForm.validateFields();
    const { title, place, description } = newCompForm.getFieldsValue();
    const date = moment(newCompForm.getFieldsValue().date).format("x");
    const creator = authStore.user.id;
    try {
      await CompService.create(title, date, place, description, creator);
      onSuccses("Добавлено новое соревнование");
    } catch (e) {
      newCompForm.setFields(serverErrorHandler(e.response?.data));
    }
  };

  return (
    <Modal
      open={open}
      title="Создать новое соревнование"
      okText="Создать"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form
        form={newCompForm}
        layout="vertical"
        name="new_comp_form"
        initialValues={{ date: moment() }}
      >
        <Form.Item
          label="Название"
          name="title"
          rules={[{ required: true, message: "Пожалуйста введите название!" }]}
        >
          <Input placeholder="Название" />
        </Form.Item>

        <Form.Item
          label="Дата проведения"
          name="date"
          rules={[
            { required: true, message: "Пожалуйста введите дату проведения!" },
          ]}
        >
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>

        <Form.Item label="Место проведения" name="place">
          <Input placeholder="Место проведения (город)" />
        </Form.Item>

        <Form.Item label="Описание" name="description">
          <Input.TextArea
            placeholder="Описание соревнования"
            autoSize={{ minRows: 1, maxRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewCompModal;
