import { Form, Input, Modal } from "antd";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import serverErrorHandler from "../../utils/serverValidationHandler";

function PasswordChangeModal({ open, onSuccses, onCancel }) {
  const [passwordForm] = Form.useForm();
  const { userid } = useParams();

  const onOk = async () => {
    try {
      await UserService.changePassword(
        userid,
        passwordForm.getFieldValue("password"),
        passwordForm.getFieldValue("newPassword")
      );
      onSuccses("Пароль успешно изменен");
    } catch (e) {
      passwordForm.setFields(serverErrorHandler(e.response?.data));
    }
  };

  return (
    <Modal
      open={open}
      title="Сменить пароль"
      okText="Сохранить"
      cancelText="Отмена"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={passwordForm} layout="vertical" name="password_change_form">
        <Form.Item
          label="Текущий пароль"
          name="password"
          rules={[{ required: true, message: "Пожалуйста введите пароль!" }]}
        >
          <Input.Password placeholder="текущий пароль" />
        </Form.Item>

        <Form.Item
          label="Новый пароль"
          name="newPassword"
          rules={[
            { required: true, message: "Пожалуйста введите новый пароль!" },
          ]}
        >
          <Input.Password placeholder="новый пароль" />
        </Form.Item>

        <Form.Item
          label="Подтвердить новый пароль"
          dependencies={["newPassword"]}
          name="confirm"
          rules={[
            { required: true, message: "Пожалуйста подтвердите новый пароль!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Введенные пароли не совпадают!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="новый пароль (еще раз)" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PasswordChangeModal;
