import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import AppFooter from "../AppFooter/AppFooter";
import AppHeader from "../AppHeader/AppHeader";

function MainLayout() {
  return (
    <Layout className="layout">
      <AppHeader />
      <Layout.Content style={{ padding: "0 50px", marginTop: "50px" }}>
        <div className="site-layout-content">
          <Outlet />
        </div>
      </Layout.Content>
      <AppFooter />
    </Layout>
  );
}

export default MainLayout;
