import api from "../utils/api.js";

class CompService {
  static async create(title, date, place, description, creator) {
    return api.post("/competition/create", {
      title,
      date,
      place,
      description,
      creator,
    });
  }

  static async getAll() {
    return api.get("/competition/all");
  }

  static async getOne(compid) {
    return api.get("/competition/" + compid);
  }

  static async update(compid, title, date, place, description) {
    return api.put("/competition/" + compid, {
      title,
      date,
      place,
      description,
    });
  }
}

export default CompService;
