import { UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../index";

function RegistrationForm() {
  const [RegistrationFormInstance] = Form.useForm();
  const { authStore } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    setLoading(true);
    const { email, password, lastname, firstname, middlename } =
      RegistrationFormInstance.getFieldsValue();
    await authStore.register(email, password, lastname, firstname, middlename);
    setLoading(false);
  };

  return (
    <Form
      name="registration-form"
      form={RegistrationFormInstance}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="horizontal"
    >
      <Col xs={{ offset: 0 }} sm={{ offset: 6 }}>
        <Typography.Title>
          <UserAddOutlined style={{ fontSize: "4rem" }} /> Регистрация
        </Typography.Title>
      </Col>

      <Form.Item
        name="email"
        label="Почта"
        rules={[
          {
            type: "email",
            message: "Введите корректный e-mail!",
          },
          {
            required: true,
            message: "Пожалуйста введите свой e-mail!",
          },
        ]}
      >
        <Input placeholder="e-mail" />
      </Form.Item>

      <Form.Item label="Пароль" required style={{ marginBottom: 0 }}>
        <Row gutter={20} style={{ marginBottom: "0" }}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="password"
              rules={[
                { required: true, message: "Пожалуйста введите пароль!" },
              ]}
            >
              <Input.Password placeholder="пароль" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              dependencies={["password"]}
              name="confirm"
              rules={[
                { required: true, message: "Пожалуйста подтвердите пароль!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Введенные пароли не совпадают!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="пароль (еще раз)" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        name="lastname"
        label="Фамилия"
        rules={[
          {
            required: true,
            message: "Пожалуйста введите фамилию!",
          },
        ]}
      >
        <Input placeholder="Фамилия" />
      </Form.Item>
      <Form.Item
        name="firstname"
        label="Имя"
        rules={[
          {
            required: true,
            message: "Пожалуйста введите имя!",
          },
        ]}
      >
        <Input placeholder="Имя" />
      </Form.Item>

      <Form.Item name="middlename" label="Отчество">
        <Input placeholder="Отчество" />
      </Form.Item>
      <Col xs={{ offset: 0 }} sm={{ offset: 6 }}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "8px" }}
            loading={loading}
          >
            Зарегистрироваться
          </Button>
          <Typography.Text>Уже зарегистрированы? </Typography.Text>
          <Link to="/login">Войдите!</Link>
        </Form.Item>
      </Col>
    </Form>
  );
}

export default observer(RegistrationForm);
