import { Button, Descriptions } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../index";

function CompPage() {
  const { compid } = useParams();
  const { authStore, compStore } = useContext(Context);

  // async function

  useEffect(() => {
    compStore.LoadCompInfo(compid);
  }, [compStore, compid]);

  return (
    <Descriptions
      bordered
      layout="vertical"
      column={2}
      size="small"
      title={compStore.compInfo.title}
      extra={
        <Button onClick={() => compStore.LoadCompInfo(compid)}>
          Нажми меня
        </Button>
      }
    >
      <Descriptions.Item label="Дата создания">
        {moment(compStore.compInfo.createdAt).format("DD.MM.YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="Дата проведения">
        {moment(compStore.compInfo.date).format("DD.MM.YYYY")}
      </Descriptions.Item>
      <Descriptions.Item label="Организатор">
        {compStore.compCreator.firstname + " " + compStore.compCreator.lastname}
      </Descriptions.Item>
      <Descriptions.Item label="Место проведения">
        {compStore.compInfo.place}
      </Descriptions.Item>

      <Descriptions.Item label="Описание" span={2}>
        {compStore.compInfo.description}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default observer(CompPage);
