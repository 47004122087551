import api from "../utils/api.js";

class UserService {
  static async getOne(id) {
    return api.get("/user/" + id);
  }

  static async update(id, lastname, firstname, middlename) {
    return api.put("/user/" + id, { lastname, firstname, middlename });
  }

  static async changePassword(id, password, newPassword) {
    return api.put("/user/" + id + "/password", {
      id,
      password,
      newPassword,
    });
  }
}

export default UserService;
