import { message } from "antd";

function serverErrorHandler(serverError) {
  message.error(serverError?.message, 4);

  //Это безумие неплохо бы рефакторнуть...

  // Сперва собираем в объект чтобы не херачить цифлы на повтор ключа
  const errorMsgObj = serverError?.errors.reduce((acc, err) => {
    return acc[err.param]
      ? { ...acc, [err.param]: [...acc[err.param], err.msg] }
      : { ...acc, [err.param]: [err.msg] };
  }, {});

  // Потом преобразуем объект в список, в формате который ждут формы для текста валидации
  return Object.keys(errorMsgObj).map((key) => ({
    name: key,
    errors: [...errorMsgObj[key]],
  }));
}

export default serverErrorHandler;
