import { message } from "antd";
import { makeAutoObservable } from "mobx";
import CompService from "../services/comp.service";

class CompStore {
  id = null;
  compInfo = {
    title: null,
    date: null,
    place: null,
    description: null,
    createdAt: null,
    updatedAt: null,
  };
  compCreator = { id: null, lastname: null, firstname: null, middlename: null };
  athletes = [];
  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  setId(compid) {
    this.id = compid;
  }

  setInfo(object) {
    this.compInfo = { ...object };
  }

  setCreator(object) {
    this.compCreator = { ...object };
  }

  setAthletes(arr) {
    Array.isArray(arr) ? (this.athletes = [...arr]) : (this.athletes = null);
  }

  clearComp() {
    //Скорее всего - херня
    this.setId(null);
    this.setInfo(CompStore.compInfo);
    this.setCreator(CompStore.compCreator);
    this.setAthletes(CompStore.athletes);
  }

  async LoadCompInfo(compid) {
    try {
      const compData = await CompService.getOne(compid);
      const { title, date, place, description, createdAt, updatedAt } =
        compData.data;
      const { lastname, firstname, middlename } = compData.data.org.creator;
      this.setId(compid);
      this.setInfo({ title, date, place, description, createdAt, updatedAt });
      this.setCreator({
        id: compData.data.org.creator._id,
        lastname,
        firstname,
        middlename,
      });
      this.setAthletes(compData.athletes);
    } catch (e) {
      e.response?.data?.message
        ? message.error(e.response?.data?.message)
        : console.log("Ошибка без сообщения: ", e);
      return e.response?.data;
    }
  }
}

export default CompStore;
