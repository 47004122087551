import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, message, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "..";
import NewCompModal from "../components/NewCompModal/NewCompModal";
import CompService from "../services/comp.service";

function CompList() {
  const [data, setData] = useState([
    {
      key: "1",
      comptitle: "Загрузка...",
      date: moment().format("DD.MM.YYYY"),
      creator: "Загрузка...",
    },
  ]);

  const { authStore } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onModalSuccses = (value) => {
    setModalOpen(false);
    message.success(value);
  };

  useEffect(() => {
    const fetchComps = async (id) => {
      const compsData = await CompService.getAll();
      const tableData = compsData.data.map((comp) => ({
        key: comp._id,
        comptitle: comp.title,
        date: moment(comp.date).format("DD.MM.YYYY"),
        creator: comp.org.creator.firstname + " " + comp.org.creator.lastname,
        dateSerial: comp.date,
        creatorId: comp.org.creator._id,
      }));
      setData(tableData);
    };

    setIsLoading(true);
    try {
      fetchComps();
    } catch (e) {
      setIsError(true);

      setData(e.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }, [modalOpen]); //Удали эту зависимость потом!

  const columns = [
    {
      title: "Соревнование",
      dataIndex: "comptitle",
      key: "comptitle",
      render: (text, record) => (
        <Link to={`/competition/${record.key}`}>{text}</Link>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.dateSerial > b.dateSerial,
    },
    {
      title: "Организатор",
      dataIndex: "creator",
      key: "creator",
    },
  ];

  // const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
  // };

  return isError ? (
    <>
      <h2>Произошла непредвиденная ошибка...</h2>
      <p>{JSON.stringify(data)}</p>
    </>
  ) : (
    <>
      {authStore.isAuth && (
        <Button
          type="primary"
          htmlType="button"
          style={{ float: "left", marginBottom: "20px" }}
          onClick={() => setModalOpen(true)}
          icon={<PlusCircleOutlined />}
        >
          Создать новое соревнование
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isLoading}
        // onChange={onChange}
        // locale={{
        //   triggerDesc: "Сперва новые",
        //   triggerAsc: "Сперва старые",
        //   cancelSort: "Отменить сортировку",
        // }}
      />
      <NewCompModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onSuccses={onModalSuccses}
      />
    </>
  );
}

export default CompList;
