import { Skeleton } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../index";
import CompList from "../../pages/CompList";
import CompPage from "../../pages/CompPage";
import LoginForm from "../../pages/LoginForm";
import RegisterForm from "../../pages/RegisterForm";
import UserProfile from "../../pages/UserProfile";
import MainLayout from "../MainLayout/MainLayout";

function AppRouter() {
  const { authStore } = useContext(Context);

  return authStore.isLoading ? (
    // Идет загрузка
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="*" element={<Skeleton active={true} />} />
      </Route>
    </Routes>
  ) : !authStore.isAuth ? (
    //Публичные пути
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<CompList />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/competition/:compid" element={<CompPage />} />
        <Route path="*" element={<CompList />} />
      </Route>
    </Routes>
  ) : (
    //Авторизованные пути
    <Routes>
      <Route element={<MainLayout />}>
        <Route exact path="/" element={<CompList />} />
        <Route exact path="/user/:userid" element={<UserProfile />} />
        <Route path="/competition/:compid" element={<CompPage />} />
        <Route path="*" element={<CompList />} />
      </Route>
    </Routes>
  );
}

export default observer(AppRouter);
