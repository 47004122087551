import { Layout, Typography } from "antd";
import React from "react";

function AppFooter() {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      <Typography.Text type="secondary">Шепелев Сергей ©2022</Typography.Text>
    </Layout.Footer>
  );
}

export default AppFooter;
