import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../index";

function LoginForm() {
  const [loginFormInstance] = Form.useForm();
  const { authStore } = useContext(Context);

  const onFinish = async () => {
    //сообщение об ошибке уже обработано в сторе
    await authStore.login(
      loginFormInstance.getFieldValue("email"),
      loginFormInstance.getFieldValue("password")
    );
  };

  return (
    <Form
      name="login-form"
      form={loginFormInstance}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="horizontal"
    >
      <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
        <Typography.Title>
          <UserOutlined style={{ fontSize: "4rem" }} /> Вход
        </Typography.Title>
      </Form.Item>

      <Form.Item
        name="email"
        label="Почта"
        rules={[
          {
            type: "email",
            message: "Введите корректный e-mail!",
          },
          {
            required: true,
            message: "Пожалуйста введите свой e-mail!",
          },
        ]}
      >
        <Input placeholder="e-mail" />
      </Form.Item>

      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: "Пожалуйста введите пароль!" }]}
      >
        <Input.Password placeholder="пароль" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          Войти
        </Button>
        <Typography.Text>Еще нет аккаунта? </Typography.Text>
        <Link to="/register">Зарегистрируйтесь!</Link>
      </Form.Item>
    </Form>
  );
}

export default observer(LoginForm);
