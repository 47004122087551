import AuthStore from "./auth.store";
import CompStore from "./comp.store";

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
    this.compStore = new CompStore(this);
  }
}

export default new RootStore();
