import { message } from "antd";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/auth.service";

class AuthStore {
  user = { id: null, email: null, isActivated: false };
  isAuth = false;
  isLoading = true;
  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setUser(user) {
    this.user = user;
  }

  setLoading(bool) {
    this.isLoading = bool;
  }

  async login(email, password) {
    try {
      const response = await AuthService.login(email, password);
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      message.error(e.response?.data?.message);
      //console.log(e.response?.data?.message);
      return e.response?.data;
    }
  }

  async register(email, password, lastname, firstname, middlename) {
    try {
      const response = await AuthService.register(
        email,
        password,
        lastname,
        firstname,
        middlename
      );
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
      message.success("Регистрация прошла успешно!");
      message.info("На почту отправлена ссылка для активации.");
    } catch (e) {
      message.error(e.response?.data?.message);
      //console.log(e.response?.data?.message);
    }
  }

  async logout() {
    try {
      await AuthService.logout();
      localStorage.removeItem("token");
      this.setAuth(false);
      this.setUser({ id: null, isActivated: false });
    } catch (e) {
      message.error(e.response?.data?.message);
      //console.log(e.response?.data?.message);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const response = await AuthService.refresh();
      localStorage.setItem("token", response.data.accessToken);
      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (e) {
      console.log(e.response?.data?.message);
    } finally {
      this.setLoading(false);
    }
  }
}

export default AuthStore;
