import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../index";
import MenuLoggedIn from "./MenuLoggedIn";
import MenuLoggedOut from "./MenuLoggedOut";

function AppHeader() {
  const { authStore } = useContext(Context);

  return (
    <Layout.Header>
      <div className="logo">
        <Link to="/">
          <h1>Мастер техники</h1>
        </Link>
      </div>
      {authStore.isAuth ? <MenuLoggedIn /> : <MenuLoggedOut />}
    </Layout.Header>
  );
}

export default observer(AppHeader);
